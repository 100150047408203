import resourcesKeys from '../../constants/resourcesKeys';

export const DEFAULT_PERSONAL_INFOS = {
	name: '',
	surname: '',
	radioName: '',
	radioTuning: '',
	email: '',
	cpf: '___.___.___-__',
	cnpj: '__.___.___/____-__',
	phone: '(__) _ ____-____',
	city: '',
	state: undefined,
};

export const DEFAULT_SOCIAL_MEDIA_INFOS = {
	website: '',
	instagram: '',
	youtube: '',
	facebook: '',
	tiktok: '',
};

export const DEFAULT_ACCOUNT_INFOS = {
	type: undefined,
	withLiveContent: undefined,
	caster: undefined,
	intranetId: '',
	paymentDay: undefined,
	password: '',
	passwordConfirmation: '',
};

export const DEFAULT_PERMISSIONS = {
	RADIO_SIGNATURE: 'OPTIONAL',
	TRACK_SIGNATURE: false,
	NOCTURNAL_GENERATION: false,
	AUTO_DOWNLOAD: false,
	MAX_GENERATIONS_PER_PROGRAM: 2,
	MASTERIZATION: 'OPTIONAL',
	PROGRAMS_LIMIT: 5,
	MAX_BLOCKS_PER_PROGRAM: 9,
	MAX_MUSICS_PER_BLOCK: 5,
	PROGRAMS_SHARING: false,
};

function getDefaultModuleValue(key, defaultValue, lead) {
	if (lead) {
		return lead.planId?.resources?.includes(key) || defaultValue || false;
	}

	return defaultValue || false;
}

export const DEFAULT_MODULES = ({ defaultValues, lead } = {}) => {
	return {
		tracks: {
			TRACKS_LOCAL: getDefaultModuleValue(resourcesKeys.TRACKS_LOCAL, defaultValues, lead),
			TRACKS_GLOBAL: getDefaultModuleValue(resourcesKeys.TRACKS_GLOBAL, defaultValues, lead),
			TRACKS_DOWNLOAD: getDefaultModuleValue(resourcesKeys.TRACKS_DOWNLOAD, defaultValues, lead),
			PLAYLIST: getDefaultModuleValue(resourcesKeys.PLAYLIST, defaultValues, lead),
		},
		news: {
			NEWS_MAIN: getDefaultModuleValue(resourcesKeys.NEWS_MAIN, defaultValues, lead),
			NEWS_LOCAL: getDefaultModuleValue(resourcesKeys.NEWS_LOCAL, defaultValues, lead),
			NEWS_GLOBAL: getDefaultModuleValue(resourcesKeys.NEWS_GLOBAL, defaultValues, lead),
			NEWS_SHARING: getDefaultModuleValue(resourcesKeys.NEWS_SHARING, defaultValues, lead),
		},
		wpps: {
			WPP_MAIN: getDefaultModuleValue(resourcesKeys.WPP_MAIN, defaultValues, lead),
			WPP_LOCAL: getDefaultModuleValue(resourcesKeys.WPP_LOCAL, defaultValues, lead),
			WPP_GLOBAL: getDefaultModuleValue(resourcesKeys.WPP_GLOBAL, defaultValues, lead),
			WPP_SHARING: getDefaultModuleValue(resourcesKeys.WPP_SHARING, defaultValues, lead),
		},
		interviews: {
			INTERVIEW_MAIN: getDefaultModuleValue(resourcesKeys.INTERVIEW_MAIN, defaultValues, lead),
			INTERVIEW_LOCAL: getDefaultModuleValue(resourcesKeys.INTERVIEW_LOCAL, defaultValues, lead),
			INTERVIEW_GLOBAL: getDefaultModuleValue(resourcesKeys.INTERVIEW_GLOBAL, defaultValues, lead),
			INTERVIEW_SHARING: getDefaultModuleValue(
				resourcesKeys.INTERVIEW_SHARING,
				defaultValues,
				lead
			),
		},
		offs: {
			OFFS_MAIN: getDefaultModuleValue(resourcesKeys.OFFS_MAIN, defaultValues, lead),
			OFFS_LOCAL: getDefaultModuleValue(resourcesKeys.OFFS_LOCAL, defaultValues, lead),
			TK_VOX: getDefaultModuleValue(resourcesKeys.TK_VOX, defaultValues, lead),
			TK_PLAY: getDefaultModuleValue(resourcesKeys.TK_PLAY, defaultValues, lead),
			IA_LOCAL: getDefaultModuleValue(resourcesKeys.IA_LOCAL, defaultValues, lead),
			IA_GLOBAL: getDefaultModuleValue(resourcesKeys.IA_GLOBAL, defaultValues, lead),
			SOUNDTRACK: getDefaultModuleValue(resourcesKeys.SOUNDTRACK, defaultValues, lead),
		},
		ads: {
			SPONSOR: getDefaultModuleValue(resourcesKeys.SPONSOR, defaultValues, lead),
			OFFERING: getDefaultModuleValue(resourcesKeys.OFFERING, defaultValues, lead),
			CASHTRACK: getDefaultModuleValue(resourcesKeys.CASHTRACK, defaultValues, lead),
			TESTIMONIAL: getDefaultModuleValue(resourcesKeys.TESTIMONIAL, defaultValues, lead),
			TRACK_AD: getDefaultModuleValue(resourcesKeys.TRACK_AD, defaultValues, lead),
			CUSTOMS: getDefaultModuleValue(resourcesKeys.CUSTOMS, defaultValues, lead),
		},
		blitz: {
			BLITZ_MAIN: getDefaultModuleValue(resourcesKeys.BLITZ_MAIN, defaultValues, lead),
			BLITZ_LOCAL: getDefaultModuleValue(resourcesKeys.BLITZ_LOCAL, defaultValues, lead),
			BLITZ_GLOBAL: getDefaultModuleValue(resourcesKeys.BLITZ_GLOBAL, defaultValues, lead),
			BLITZ_SHARING: getDefaultModuleValue(resourcesKeys.BLITZ_SHARING, defaultValues, lead),
		},
		other: {
			TAG: getDefaultModuleValue(resourcesKeys.TAG, defaultValues, lead),
			SIGNATURES: getDefaultModuleValue(resourcesKeys.SIGNATURES, defaultValues, lead),
			RECPLAY_MODULE: getDefaultModuleValue(resourcesKeys.RECPLAY_MODULE, defaultValues, lead),
			CASTER_MODULE: getDefaultModuleValue(resourcesKeys.CASTER_MODULE, defaultValues, lead),
		},
		vignettes: {
			VIGNETTES_RADIO_SCHEDULED: getDefaultModuleValue(
				resourcesKeys.VIGNETTES_RADIO_SCHEDULED,
				defaultValues,
				lead
			),
			VIGNETTES_RADIO_CONTINUOUS: getDefaultModuleValue(
				resourcesKeys.VIGNETTES_RADIO_CONTINUOUS,
				defaultValues,
				lead
			),
			VIGNETTES_PROGRAM: getDefaultModuleValue(
				resourcesKeys.VIGNETTES_PROGRAM,
				defaultValues,
				lead
			),
		},
	};
};
