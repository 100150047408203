import React, { useCallback, useEffect, useState } from 'react';

import UpsertUserContext from './context';
import {
	DEFAULT_ACCOUNT_INFOS,
	DEFAULT_PERSONAL_INFOS,
	DEFAULT_SOCIAL_MEDIA_INFOS,
	DEFAULT_PERMISSIONS,
	DEFAULT_MODULES,
} from './defaultData';
import { userTypes } from '../../constants';
import { PlanTypes } from '../../enums/plans';

const UpsertUserProvider = ({ action, children }) => {
	const [personalInfos, setPersonalInfos] = useState(DEFAULT_PERSONAL_INFOS);
	const [accountInfos, setAccountInfos] = useState(DEFAULT_ACCOUNT_INFOS);
	const [socialMediaInfos, setSocialMediaInfos] = useState(DEFAULT_SOCIAL_MEDIA_INFOS);
	const [permissions, setPermissions] = useState(DEFAULT_PERMISSIONS);
	const [modules, setModules] = useState(DEFAULT_MODULES());
	const [network, setNetwork] = useState([]);
	const [lead, setLead] = useState(null);

	const handleChangePersonalInfo = useCallback((e) => {
		e?.persist && e.persist();
		setPersonalInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const handleChangeSocialMediaInfo = useCallback((e) => {
		e?.persist && e.persist();
		setSocialMediaInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const handleChangeAccountInfo = useCallback((e) => {
		e?.persist && e.persist();
		setAccountInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const handleChangePermission = useCallback((key, value) => {
		setPermissions((prev) => ({ ...prev, [key]: value }));
	}, []);

	const getModuleState = useCallback((subitems) => {
		const checked = Object.values(subitems).every((v) => v);
		const indeterminate = checked ? false : Object.values(subitems).some((v) => v);

		return {
			checked,
			indeterminate,
		};
	}, []);

	const onModuleCheckboxClick = useCallback(
		(key) => {
			setModules((modules) => {
				const subitems = modules[key];
				const { checked } = getModuleState(subitems);

				for (const subitemKey in subitems) {
					subitems[subitemKey] = !checked;
				}

				return { ...modules, [key]: subitems };
			});
		},
		[getModuleState]
	);

	const onModuleSubitemCheckboxClick = useCallback((moduleKey, itemKey) => {
		setModules((modules) => {
			const value = modules[moduleKey][itemKey];
			modules[moduleKey][itemKey] = !value;
			return { ...modules };
		});
	}, []);

	const getData = useCallback(() => {
		const user = { ...personalInfos, ...accountInfos, socialMedia: socialMediaInfos };
		const resources = {
			...modules,
			contentGeneration: {
				RADIO_SIGNATURE: permissions.RADIO_SIGNATURE,
				TRACK_SIGNATURE: permissions.TRACK_SIGNATURE,
				NOCTURNAL_GENERATION: permissions.NOCTURNAL_GENERATION,
				AUTO_DOWNLOAD: permissions.AUTO_DOWNLOAD,
				MAX_GENERATIONS_PER_PROGRAM: permissions.MAX_GENERATIONS_PER_PROGRAM,
				MASTERIZATION: permissions.MASTERIZATION,
			},
			contentCreation: {
				PROGRAMS_LIMIT: permissions.PROGRAMS_LIMIT,
				MAX_BLOCKS_PER_PROGRAM: permissions.MAX_BLOCKS_PER_PROGRAM,
				MAX_MUSICS_PER_BLOCK: permissions.MAX_MUSICS_PER_BLOCK,
				PROGRAMS_SHARING: permissions.PROGRAMS_SHARING,
			},
		};

		return {
			user,
			resources,
			leadId: lead?._id || null,
			network: permissions.PROGRAMS_SHARING ? network : [],
		};
	}, [network, accountInfos, modules, permissions, personalInfos, lead, socialMediaInfos]);

	const initUser = useCallback(
		(data) => {
			setPersonalInfos((prev) => ({
				...prev,
				name: data.user?.name,
				radioTuning: data.user?.radioTuning,
				surname: data.user?.surname,
				radioName: data.user?.radioName,
				email: data.user?.email,
				cpf: data.user?.cpf || '___.___.___-__',
				cnpj: data.user?.cnpj || '__.___.___/____-__',
				phone: data.user?.phone,
				city: data.user?.city,
				state: data.user?.state,
			}));

			setAccountInfos((prev) => ({
				...prev,
				type: data.user?.type,
				withLiveContent: data.user?.withLiveContent,
				caster: data.user?.caster || undefined,
				intranetId: data.user?.intranetId || '',
				paymentDay: data.user?.paymentDay || undefined,
				password: data.user?.password,
				passwordConfirmation: data.user?.passwordConfirmation,
			}));

			setSocialMediaInfos((prev) => ({
				...prev,
				website: data.user?.socialMedia?.website || '',
				instagram: data.user?.socialMedia?.instagram || '',
				youtube: data.user?.socialMedia?.youtube || '',
				facebook: data.user?.socialMedia?.facebook || '',
				tiktok: data.user?.socialMedia?.tiktok || '',
			}));

			if (data.network?.users) {
				setNetwork(data.network.users);
			}

			if (data.user?.resourcesId) {
				setModules((prev) => ({
					...prev,
					tracks: { ...DEFAULT_MODULES({ lead }).tracks, ...data.user?.resourcesId.tracks },
					news: { ...DEFAULT_MODULES({ lead }).news, ...data.user?.resourcesId.news },
					wpps: { ...DEFAULT_MODULES({ lead }).wpps, ...data.user?.resourcesId.wpps },
					interviews: {
						...DEFAULT_MODULES({ lead }).interviews,
						...data.user?.resourcesId.interviews,
					},
					offs: { ...DEFAULT_MODULES({ lead }).offs, ...data.user?.resourcesId.offs },
					ads: { ...DEFAULT_MODULES({ lead }).ads, ...data.user?.resourcesId.ads },
					blitz: { ...DEFAULT_MODULES({ lead }).blitz, ...data.user?.resourcesId.blitz },
					vignettes: {
						...DEFAULT_MODULES({ lead }).vignettes,
						...data.user?.resourcesId.vignettes,
					},
					other: { ...DEFAULT_MODULES({ lead }).other, ...data.user?.resourcesId.other },
				}));

				setPermissions((prev) => ({
					...prev,
					RADIO_SIGNATURE: data.user?.resourcesId.contentGeneration.RADIO_SIGNATURE,
					TRACK_SIGNATURE: data.user?.resourcesId.contentGeneration.TRACK_SIGNATURE,
					NOCTURNAL_GENERATION: data.user?.resourcesId.contentGeneration.NOCTURNAL_GENERATION,
					AUTO_DOWNLOAD: data.user?.resourcesId.contentGeneration.AUTO_DOWNLOAD,
					MAX_GENERATIONS_PER_PROGRAM:
						data.user?.resourcesId.contentGeneration.MAX_GENERATIONS_PER_PROGRAM,
					MASTERIZATION: data.user?.resourcesId.contentGeneration.MASTERIZATION,
					PROGRAMS_LIMIT: data.user?.resourcesId.contentCreation.PROGRAMS_LIMIT,
					MAX_BLOCKS_PER_PROGRAM: data.user?.resourcesId.contentCreation.MAX_BLOCKS_PER_PROGRAM,
					MAX_MUSICS_PER_BLOCK: data.user?.resourcesId.contentCreation.MAX_MUSICS_PER_BLOCK,
					PROGRAMS_SHARING: data.user?.resourcesId.contentCreation.PROGRAMS_SHARING,
				}));
			}
		},
		[lead]
	);

	useEffect(() => {
		if (lead) {
			const [name, ...surname] = lead.name.split(' ');

			setPersonalInfos((prev) => ({
				...prev,
				name,
				surname: surname.join(' '),
				email: lead.email,
				radioName: lead.radioName,
				phone: lead.phone,
			}));

			setAccountInfos((prev) => ({
				...prev,
				type: userTypes.COMMON,
			}));

			setModules((prev) => ({
				...prev,
				tracks: { ...DEFAULT_MODULES({ lead }).tracks },
				news: { ...DEFAULT_MODULES({ lead }).news },
				wpps: { ...DEFAULT_MODULES({ lead }).wpps },
				interviews: { ...DEFAULT_MODULES({ lead }).interviews },
				offs: { ...DEFAULT_MODULES({ lead }).offs },
				ads: { ...DEFAULT_MODULES({ lead }).ads },
				blitz: { ...DEFAULT_MODULES({ lead }).blitz },
				vignettes: { ...DEFAULT_MODULES({ lead }).vignettes },
				other: { ...DEFAULT_MODULES({ lead }).other },
			}));

			setPermissions((prev) => ({
				...prev,
				PROGRAMS_SHARING: lead.planId?.type === PlanTypes.ENTERPRISE,
			}));
		}
	}, [lead]);

	useEffect(() => {
		if (action === 'CREATE') {
			setModules(DEFAULT_MODULES({ lead, defaultValues: accountInfos.type === 'ADMIN' }));
		}
	}, [action, accountInfos.type, lead]);

	return (
		<UpsertUserContext.Provider
			value={{
				personalInfos,
				accountInfos,
				permissions,
				modules,
				handleChangePermission,
				handleChangeAccountInfo,
				socialMediaInfos,
				handleChangeSocialMediaInfo,
				handleChangePersonalInfo,
				getModuleState,
				onModuleCheckboxClick,
				onModuleSubitemCheckboxClick,
				network,
				setNetwork,
				getData,
				initUser,
				lead,
				setLead,
			}}>
			{children}
		</UpsertUserContext.Provider>
	);
};

export default UpsertUserProvider;
