import { useContext } from 'react';
import CreateProgramContext from './context';

const useCreateProgram = () => {
	return useContext(CreateProgramContext);
};

export { useCreateProgram };
export { default as CreateProgramContext } from './context';
export { default as CreateProgramProvider } from './provider';
