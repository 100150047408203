import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Typography, message, Card, PageHeader, Steps } from 'antd';
import { useHistory } from 'react-router-dom';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { Container, StepsContainer, StepContentContainer } from './styles';

import ProgramsAPI from '../../../../services/sdks/programs';
import GenresAPI from '../../../../services/sdks/genres';
import { ProgramInfosStep } from './Steps/ProgramInfos';
import { ProgramVignettesStep } from './Steps/ProgramVignettesStep';
import { useCreateProgram } from '../../../../contexts/CreateProgram';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Conteúdos' },
		{ breadcrumbName: 'Programas' },
		{ breadcrumbName: 'Novo Programa' },
	],
	style: { marginBottom: 12 },
};

const CreateProgram = ({ user }) => {
	const history = useHistory();
	const [fallback, setFallback] = useState({ initialData: true });
	const [genres, setGenres] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);

	const nextStep = useCallback(() => setCurrentStep((prev) => prev + 1), []);
	const prevStep = useCallback(() => setCurrentStep((prev) => prev - 1), []);

	const { infos, getVignettesPromises } = useCreateProgram();

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { genres },
				} = await GenresAPI.index();

				setGenres(genres);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os gêneros, tente novamente');
			}
		};

		fetchInitialData();
	}, []);

	const createProgram = useCallback(async () => {
		try {
			message.loading('Criando programa', 100000);

			const {
				data: { program },
			} = await ProgramsAPI.store({ ...infos, userId: user._id });

			message.destroy();
			message.loading('Cadastrando vinhetas', 100000);
			await Promise.all(getVignettesPromises({ userId: user._id, programId: program._id }));

			message.destroy();
			message.success('Programa cadastrado com sucesso', 3, () => {
				history.push(`/commom/programs/${program._id}/details`);
			});
		} catch (error) {
			if (error?.status?.code === 400) {
				return message.error('Limite de programas foi atingido');
			}

			message.error('Houve um erro, tente nvoamente');
		}
	}, [infos, user, getVignettesPromises, history]);

	const steps = useMemo(
		() => [
			{ title: 'Informações Principais', description: 'Principais informações do programa' },
			{ title: 'Vinhetas (Opcional)', description: 'Cadastrar vinhetas do programa' },
		],
		[]
	);

	const stepsContents = useMemo(() => {
		return [
			<ProgramInfosStep genres={genres} nextStep={nextStep} />,
			<ProgramVignettesStep createProgram={createProgram} prevStep={prevStep} />,
		];
	}, [genres, nextStep, prevStep, createProgram]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Novo Programa' />

			<PageHeader title='Novo Programa' breadcrumb={breadcrumb}>
				<Typography.Text>Cadastrar novo programa</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<StepsContainer>
						<Steps direction='vertical' current={currentStep}>
							{steps.map((step) =>
								step ? (
									<Steps.Step key={step.title} title={step.title} description={step.description} />
								) : null
							)}
						</Steps>
						<StepContentContainer>{stepsContents[currentStep]}</StepContentContainer>
					</StepsContainer>
				</Card>
			</Container>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateProgram);
