export const LeadsStatus = {
	NEW: 'NEW',
	AWAITING_RESPONSE: 'AWAITING-RESPONSE',
	IN_CONTACT: 'IN-CONTACT',
	PENDING_APPROVAL: 'PENDING-APPROVAL',
	CONVERTED: 'CONVERTED',
	FAILED: 'FAILED',
};

export const LeadsStatusColors = {
	NEW: '#B0BEC5',
	'AWAITING-RESPONSE': '#e1b71b',
	'IN-CONTACT': '#358cc9',
	'PENDING-APPROVAL': '#FF9800',
	CONVERTED: '#4CAF50',
	FAILED: '#D32F2F',
};

export const LeadsStatusTranslations = {
	NEW: 'NOVO',
	'AWAITING-RESPONSE': 'AGUARDANDO RESPOSTA',
	'IN-CONTACT': 'EM CONTATO',
	'PENDING-APPROVAL': 'APROVAÇÃO PENDENTE',
	CONVERTED: 'CONVERTIDO',
	FAILED: 'FALHOU',
};
