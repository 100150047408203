export const PlanTypes = {
	STARTER: 'STARTER',
	AVATAR_IA: 'AVATAR-IA',
	HUMAN_CASTER: 'HUMAN-CASTER',
	ENTERPRISE: 'ENTERPRISE',
};

export const PlanTypesTranslations = {
	STARTER: 'STARTER',
	'AVATAR-IA': 'IA AVATAR',
	'HUMAN-CASTER': 'LOCUTOR HUMANO',
	ENTERPRISE: 'REDE DE RÁDIO',
};
