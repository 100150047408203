import React, { useCallback, useMemo, useState } from 'react';
import { Input, message, Modal, Select } from 'antd';
import { LeadsStatus, LeadsStatusColors, LeadsStatusTranslations } from '../../../../enums/leads';
import SelectOptWithBadge from '../../../../components/SelectOptWithBadge';
import LeadsAPI from '../../../../services/sdks/leads';
import { PlanTypes, PlanTypesTranslations } from '../../../../enums/plans';
import { PlanDetailsContainer, PlanDetailsInfos, PlanServices } from './styles';
import { CasterVoiceTypesTranslations } from '../../../../enums/caster-voice-types';
import useFormatter from '../../../../hooks/useFormatter';
import modulesTranslations from '../../../../constants/modulesTranslations';
import Form from '../../../../components/Form';

export const ChangeLeadStatusModal = ({ isVisible, onCloseModal, lead }) => {
	const [selectedStatus, setSelectedStatus] = useState(lead?.status);
	const [isLoading, setIsLoading] = useState(false);

	const handleChangeLeadStatus = useCallback(async () => {
		setIsLoading(true);

		try {
			await LeadsAPI.changeLeadStatus(lead._id, selectedStatus);
			message.success('Status alterado com sucesso');
			setIsLoading(false);
			onCloseModal(lead._id, selectedStatus);
		} catch (error) {
			setIsLoading(false);
			onCloseModal(lead._id, null);
		}
	}, [onCloseModal, lead, selectedStatus]);

	const options = useMemo(() => {
		return Object.values(LeadsStatus).filter((status) => status !== LeadsStatus.CONVERTED);
	}, []);

	return (
		<Modal
			title='Alterar status do lead'
			okText='Alterar Status'
			visible={isVisible}
			closable={!isLoading}
			okButtonProps={{ loading: isLoading, disabled: !selectedStatus }}
			cancelButtonProps={{ disabled: isLoading }}
			onCancel={onCloseModal}
			onOk={handleChangeLeadStatus}>
			<Select
				style={{ width: '100%' }}
				size='large'
				defaultValue={lead?.status}
				value={selectedStatus}
				placeholder='Selecione o novo status'
				onChange={(status) => setSelectedStatus(status)}>
				{options.map((status) => (
					<Select.Option key={status}>
						<SelectOptWithBadge
							color={LeadsStatusColors[status]}
							label={LeadsStatusTranslations[status]}
						/>
					</Select.Option>
				))}
			</Select>
		</Modal>
	);
};

export const LeadPlanDetailsModal = ({ isVisible, onCloseModal, lead }) => {
	const plan = lead?.planId;
	const format = useFormatter({ style: 'currency', currency: 'BRL' });

	const planInfos = useMemo(() => {
		if (!plan) {
			return null;
		}

		const infos = [
			{
				label: 'Plano',
				value: PlanTypesTranslations[plan.type],
			},
			{
				label: 'Preço',
				value: plan.type === PlanTypes.STARTER ? 'Grátis' : `${format(plan.price)}/mês`,
			},
		];

		if (plan.type !== PlanTypes.STARTER) {
			infos.push({ label: 'Duração do Programa', value: plan.programDuration });
		}

		if (plan.type === PlanTypes.AVATAR_IA) {
			infos.push({
				label: 'Tipo de Voz',
				value: CasterVoiceTypesTranslations[plan.voiceType],
			});
		}

		if (plan.type === PlanTypes.ENTERPRISE) {
			infos.push({
				label: 'Nº de Programas na Rede',
				value: plan.radioQuantity,
			});
		}

		return (
			<PlanDetailsInfos>
				{infos.map((info) => (
					<div key={info.label}>
						<span>{info.label}</span>
						<strong>{info.value}</strong>
					</div>
				))}
			</PlanDetailsInfos>
		);
	}, [format, plan]);

	const planServices = useMemo(() => {
		if (!plan || plan.type === PlanTypes.STARTER) {
			return null;
		}

		return (
			<PlanServices>
				{plan.resources.map((r) => (
					<li key={r}>{modulesTranslations[r]}</li>
				))}
			</PlanServices>
		);
	}, [plan]);

	if (!plan) {
		return null;
	}

	return (
		<Modal onCancel={onCloseModal} title='Detalhes do plano' visible={isVisible} footer={null}>
			<PlanDetailsContainer>
				<div>{planInfos}</div>

				{plan.type !== PlanTypes.STARTER && (
					<div>
						<small>SERVIÇOS ADICIONAIS SELECIONADO</small>
						{planServices}
					</div>
				)}
			</PlanDetailsContainer>
		</Modal>
	);
};

export const DEFAULT_FILTERS = {
	radioName: '',
	email: '',
	name: '',
	// status: undefined,
};

export const LeadsFiltersModal = ({ isVisible, filters, setFilters, closeModal }) => {
	const [formValue, setFormValue] = useState({ ...DEFAULT_FILTERS, ...filters });

	const handleApplyFilters = useCallback(() => {
		setFilters(formValue);
		closeModal();
	}, [closeModal, formValue, setFilters]);

	const handleChangeFormValue = useCallback((key, value) => {
		setFormValue((prev) => ({ ...prev, [key]: value }));
	}, []);

	return (
		<Modal
			title='Filtrar leads'
			visible={isVisible}
			okText='Aplicar Filtros'
			onOk={handleApplyFilters}
			onCancel={() => {
				setFormValue(filters);
				closeModal();
			}}>
			<Form.Container layout='1fr'>
				{/* <Form.Item size={1} label='Status'>
					<Select
						placeholder='Selecione o tipo da conta'
						value={formValue.status}
						onChange={(value) => handleChangeFormValue('status', value)}>
						{Object.values(LeadsStatus).map((status) => (
							<Select.Option key={status}>
								<SelectOptWithBadge
									color={LeadsStatusColors[status]}
									label={LeadsStatusTranslations[status]}
								/>
							</Select.Option>
						))}
					</Select>
				</Form.Item> */}

				<Form.Item optional size={1} label='Email'>
					<Input
						value={formValue.email}
						onChange={(e) => handleChangeFormValue('email', e.target.value)}
						placeholder='Filtrar pelo email'
					/>
				</Form.Item>

				<Form.Item optional size={1} label='Nome da Rádio'>
					<Input
						value={formValue.radioName}
						onChange={(e) => handleChangeFormValue('radioName', e.target.value)}
						placeholder='Filtrar pelo nome da rádio'
					/>
				</Form.Item>

				<Form.Item optional size={1} label='Nome'>
					<Input
						value={formValue.name}
						onChange={(e) => handleChangeFormValue('name', e.target.value)}
						placeholder='Filtrar pelo nome completo'
					/>
				</Form.Item>
			</Form.Container>
		</Modal>
	);
};
