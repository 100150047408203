import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const StepsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 28% auto;
	gap: 12px;
`;

export const StepContentContainer = styled.div`
	width: 100%;
	height: 100%;
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 12px;
`;
