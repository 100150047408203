const resourcesKeys = {
	SPONSOR: 'SPONSOR',
	OFFERING: 'OFFERING',
	CASHTRACK: 'CASHTRACK',
	TESTIMONIAL: 'TESTIMONIAL',

	TRACK_AD: 'TRACK_AD',
	TRACKS_LOCAL: 'TRACKS_LOCAL',
	TRACKS_GLOBAL: 'TRACKS_GLOBAL',
	TRACKS_DOWNLOAD: 'TRACKS_DOWNLOAD',

	NEWS_MAIN: 'NEWS_MAIN',
	NEWS_LOCAL: 'NEWS_LOCAL',
	NEWS_GLOBAL: 'NEWS_GLOBAL',
	NEWS_SHARING: 'NEWS_SHARING',

	WPP_MAIN: 'WPP_MAIN',
	WPP_LOCAL: 'WPP_LOCAL',
	WPP_GLOBAL: 'WPP_GLOBAL',
	WPP_SHARING: 'WPP_SHARING',

	INTERVIEW_MAIN: 'INTERVIEW_MAIN',
	INTERVIEW_LOCAL: 'INTERVIEW_LOCAL',
	INTERVIEW_GLOBAL: 'INTERVIEW_GLOBAL',
	INTERVIEW_SHARING: 'INTERVIEW_SHARING',

	BLITZ_MAIN: 'BLITZ_MAIN',
	BLITZ_LOCAL: 'BLITZ_LOCAL',
	BLITZ_GLOBAL: 'BLITZ_GLOBAL',
	BLITZ_SHARING: 'BLITZ_SHARING',

	OFFS_MAIN: 'OFFS_MAIN',
	OFFS_LOCAL: 'OFFS_LOCAL',
	TK_VOX: 'TK_VOX',
	TK_PLAY: 'TK_PLAY',
	IA_LOCAL: 'IA_LOCAL',
	IA_GLOBAL: 'IA_GLOBAL',

	CUSTOMS: 'CUSTOMS',
	TAG: 'TAG',
	SIGNATURES: 'SIGNATURES',
	SOUNDTRACK: 'SOUNDTRACK',
	PLAYLIST: 'PLAYLIST',

	VIGNETTES_RADIO_SCHEDULED: 'VIGNETTES_RADIO_SCHEDULED',
	VIGNETTES_RADIO_CONTINUOUS: 'VIGNETTES_RADIO_CONTINUOUS',
	VIGNETTES_PROGRAM: 'VIGNETTES_PROGRAM',

	PROGRAMS_SHARING: 'PROGRAMS_SHARING',
	TRACK_SIGNATURE: 'TRACK_SIGNATURE',
	NOCTURNAL_GENERATION: 'NOCTURNAL_GENERATION',
	AUTO_DOWNLOAD: 'AUTO_DOWNLOAD',
	RADIO_SIGNATURE: 'RADIO_SIGNATURE',

	MAX_GENERATIONS_PER_PROGRAM: 'MAX_GENERATIONS_PER_PROGRAM',
	MASTERIZATION: 'MASTERIZATION',
	PROGRAMS_LIMIT: 'PROGRAMS_LIMIT',
	MAX_BLOCKS_PER_PROGRAM: 'MAX_BLOCKS_PER_PROGRAM',
	MAX_MUSICS_PER_BLOCK: 'MAX_MUSICS_PER_BLOCK',

	RECPLAY_MODULE: 'RECPLAY_MODULE',
	CASTER_MODULE: 'CASTER_MODULE',
};

export default resourcesKeys;
