export function getResource(user, resource) {
	const allResources = {
		...(user?.resourcesId?.ads || {}),
		...(user?.resourcesId?.tracks || {}),
		...(user?.resourcesId?.news || {}),
		...(user?.resourcesId?.wpps || {}),
		...(user?.resourcesId?.interviews || {}),
		...(user?.resourcesId?.offs || {}),
		...(user?.resourcesId?.vignettes || {}),

		TRACK_SIGNATURE: user?.resourcesId.contentGeneration.TRACK_SIGNATURE,
		NOCTURNAL_GENERATION: user?.resourcesId.contentGeneration.NOCTURNAL_GENERATION,
		AUTO_DOWNLOAD: user?.resourcesId.contentGeneration.AUTO_DOWNLOAD,
		RADIO_SIGNATURE: user?.resourcesId.contentGeneration.RADIO_SIGNATURE,
		MAX_GENERATIONS_PER_PROGRAM: user?.resourcesId.contentGeneration.MAX_GENERATIONS_PER_PROGRAM,
		MASTERIZATION: user?.resourcesId.contentGeneration.MASTERIZATION,

		PROGRAMS_SHARING: user?.resourcesId.contentCreation.PROGRAMS_SHARING,
		PROGRAMS_LIMIT: user?.resourcesId.contentCreation.PROGRAMS_LIMIT,
		MAX_BLOCKS_PER_PROGRAM: user?.resourcesId.contentCreation.MAX_BLOCKS_PER_PROGRAM,
		MAX_MUSICS_PER_BLOCK: user?.resourcesId.contentCreation.MAX_MUSICS_PER_BLOCK,
	};

	return allResources[resource];
}
