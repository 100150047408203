import api from '../api';

const BASE_URL = '/api/v2/leads';

const LeadsAPI = {
	listLeads: (params) => api.get(BASE_URL, { params }),
	getLeadById: (leadId) => api.get(`${BASE_URL}/${leadId}`),
	changeLeadStatus: (leadId, status) =>
		api.patch(`${BASE_URL}/${leadId}/change-status`, { status }),
};

export default LeadsAPI;
