import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeader, Card, Typography, message, Tabs, Button } from 'antd';
import { useParams } from 'react-router-dom';

import Meta from '../../../../components/Meta';
import { Container, TabContent } from './styles';

import CastersApi from '../../../../services/sdks/caster';
import UsersAPI from '../../../../services/sdks/user';
import Fallback from '../../../../components/Fallback';
import { PersonalInfos } from '../CreateUser/Steps/PersonalInfos';
import { SocialMedia } from '../CreateUser/Steps/SocialMedia';
import { AccountInfos } from '../CreateUser/Steps/AccountInfos';
import { Permissions } from '../CreateUser/Steps/Permissions';
import { Modules } from '../CreateUser/Steps/Modules';
import { Network } from '../CreateUser/Steps/Network';
import { useUpsertUser } from '../../../../contexts/UserUpsert';
import { FiGrid, FiSave, FiSettings, FiShare2, FiSliders, FiUser } from 'react-icons/fi';
import { ButtonsContainer } from '../CreateUser/styles';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Editar Usuário' },
	],
	style: { marginBottom: 12 },
};

const UpdateUser = () => {
	const { userId } = useParams();
	const [fallback, setFallback] = useState({ initialData: true });
	const [casters, setCasters] = useState([]);
	const [users, setUsers] = useState([]);
	const [isEmailTaken, setIsEmailTaken] = useState(false);
	const { permissions, getData, initUser } = useUpsertUser();

	const handleSubmit = useCallback(async () => {
		setIsEmailTaken(false);
		setFallback((prev) => ({ ...prev, updatingUser: true }));

		try {
			await UsersAPI.update(userId, getData());
			message.success('Usuário atualizado com sucesso');
		} catch (error) {
			if (error?.status === 409) {
				setIsEmailTaken(true);
				message.error('O email informado já está em uso');
			} else {
				message.error('Ocorreu um erro, tente novamente');
			}
		}

		setFallback((prev) => ({ ...prev, updatingUser: false }));
	}, [getData, userId]);

	useEffect(() => {
		const fetchInitialData = async () => {
			setFallback((prev) => ({ ...prev, initialData: true }));

			try {
				const {
					data: { casters },
				} = await CastersApi.index();

				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				const { data } = await UsersAPI.getOne(userId);

				setCasters(casters);
				setUsers(users);
				initUser(data);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados, tente novamente');
			}

			setFallback((prev) => ({ ...prev, initialData: false }));
		};

		fetchInitialData();
	}, [userId, initUser]);

	const tabs = useMemo(
		() => [
			{
				title: 'Dados Pessoais',
				icon: FiUser,
				content: <PersonalInfos action='UPDATE' isEmailTaken={isEmailTaken} />,
			},
			{
				title: 'Dados da Conta',
				icon: FiSettings,
				content: <AccountInfos action='UPDATE' casters={casters} />,
			},
			{
				title: 'Mídias Sociais',
				icon: FiSettings,
				content: <SocialMedia action='UPDATE' />,
			},
			{
				title: 'Permissões',
				icon: FiSliders,
				content: <Permissions action='UPDATE' />,
			},
			{
				title: 'Módulos',
				icon: FiGrid,
				content: <Modules action='UPDATE' />,
			},
			permissions?.PROGRAMS_SHARING
				? {
						title: 'Rede',
						icon: FiShare2,
						content: <Network action='UPDATE' users={users} />,
				  }
				: null,
		],
		[casters, isEmailTaken, permissions, users]
	);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Editar usuário' />

			<PageHeader title='Editar Usuário' breadcrumb={breadcrumb}>
				<Typography.Text>Alterar dados, permissões, módulos e rede do usuário</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Tabs size='small' tabPosition='left'>
						{tabs
							.filter((tab) => tab !== null)
							.map(({ content, title, icon: Icon }) => (
								<Tabs.TabPane
									key={title}
									tab={
										<TabContent>
											<Icon />
											<span>{title}</span>
										</TabContent>
									}>
									{content}
								</Tabs.TabPane>
							))}
					</Tabs>

					<ButtonsContainer>
						<Button
							loading={fallback.updatingUser}
							type='primary'
							size='large'
							onClick={handleSubmit}>
							<FiSave />
							Salvar Alterações
						</Button>
					</ButtonsContainer>
				</Card>
			</Container>
		</>
	);
};

export default UpdateUser;
