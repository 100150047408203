import userTypes from '../constants/userTypes';

export function checkResource(user, resource, options = { strict: true }) {
	if (!resource || user.type === userTypes.ADMIN) {
		return true;
	}

	const allResources = {
		...(user?.resourcesId?.ads || {}),
		...(user?.resourcesId?.tracks || {}),
		...(user?.resourcesId?.news || {}),
		...(user?.resourcesId?.wpps || {}),
		...(user?.resourcesId?.interviews || {}),
		...(user?.resourcesId?.offs || {}),
		...(user?.resourcesId?.vignettes || {}),
		...(user?.resourcesId?.other || {}),
		PROGRAMS_SHARING: user?.resourcesId.contentCreation.PROGRAMS_SHARING,
		TRACK_SIGNATURE: user?.resourcesId.contentGeneration.TRACK_SIGNATURE,
		NOCTURNAL_GENERATION: user?.resourcesId.contentGeneration.NOCTURNAL_GENERATION,
		AUTO_DOWNLOAD: user?.resourcesId.contentGeneration.AUTO_DOWNLOAD,
	};

	if (Array.isArray(resource)) {
		return options.strict
			? resource.every((key) => allResources[key] === true)
			: resource.some((key) => allResources[key] === true);
	}

	return allResources[resource] === true;
}

/**
 * @param {(ads|tracks|news|wpps|interviews|offs|vignettes)} group
 */
export function checkResourcesGroup(user, group) {
	if (!group || user.type === userTypes.ADMIN) {
		return true;
	}

	return Object.values(user?.resourcesId[group] || {})?.some((v) => v === true);
}
