import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import TutorialsRoutes from './Tutorials.routes';
import ADMListLeads from '../pages/Admin/Leads/ListLeads';
import ADMCreateUser from '../pages/Admin/Users/CreateUser';
import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import TutorialsLayout from '../components/TutorialsLayout';

import { SellerNavigation } from '../components/Navigation';
import { UpsertUserProvider } from '../contexts/UserUpsert';

const AdminStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path={['/', '/admin', '/login']}>
				<Redirect to='/admin/leads' />
			</Route>

			<Route exact path='/admin/leads'>
				<AppLayout isAdmin={true} navigation={<SellerNavigation />}>
					<ADMListLeads />
				</AppLayout>
			</Route>

			<Route exact path='/admin/users/create-user'>
				<AppLayout isAdmin={true} navigation={<SellerNavigation />}>
					<UpsertUserProvider action='CREATE'>
						<ADMCreateUser />
					</UpsertUserProvider>
				</AppLayout>
			</Route>

			<TutorialsLayout>
				<TutorialsRoutes />
			</TutorialsLayout>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default AdminStack;
