import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeader, Card, Steps, Typography, message, Alert } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';

import Meta from '../../../../components/Meta';
import { Container, LeadInfo, StepContentContainer, StepsContainer } from './styles';

import CastersApi from '../../../../services/sdks/caster';
import UsersAPI from '../../../../services/sdks/user';
import Fallback from '../../../../components/Fallback';
import { PersonalInfos } from './Steps/PersonalInfos';
import { AccountInfos } from './Steps/AccountInfos';
import { Permissions } from './Steps/Permissions';
import { Modules } from './Steps/Modules';
import { Network } from './Steps/Network';
import { useUpsertUser } from '../../../../contexts/UserUpsert';
import LeadsAPI from '../../../../services/sdks/leads';
import { PlanTypes } from '../../../../enums/plans';
import { SocialMedia } from './Steps/SocialMedia';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Novo Usuário' },
	],
	style: { marginBottom: 12 },
};

const NewUser = () => {
	const history = useHistory();
	const { search } = useLocation();
	const leadId = useMemo(() => {
		const qs = querystring.parse(search);
		return qs.leadId || null;
	}, [search]);
	const [currentStep, setCurrentStep] = useState(0);
	const [fallback, setFallback] = useState({ initialData: true });
	const [casters, setCasters] = useState([]);
	const [users, setUsers] = useState([]);
	const [isEmailTaken, setIsEmailTaken] = useState(false);
	const { permissions, getData, lead, setLead } = useUpsertUser();

	const handleSubmit = useCallback(async () => {
		setIsEmailTaken(false);
		setFallback((prev) => ({ ...prev, creatingUser: true }));

		try {
			const response = await UsersAPI.create(getData());
			const userId = response.data.user._id;
			message.success('Usuário criado com sucesso', 3, () =>
				history.push(`/admin/users/${userId}/details`)
			);
		} catch (error) {
			setFallback((prev) => ({ ...prev, creatingUser: false }));

			if (error?.status === 409) {
				setIsEmailTaken(true);
				setCurrentStep(0);
				message.error('O email informado já está em uso');
			} else {
				message.error('Ocorreu um erro, tente novamente');
			}
		}
	}, [getData, history]);

	useEffect(() => {
		const fetchInitialData = async () => {
			setFallback((prev) => ({ ...prev, initialData: true }));

			try {
				const {
					data: { casters },
				} = await CastersApi.index();

				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				if (leadId) {
					const {
						data: { lead },
					} = await LeadsAPI.getLeadById(leadId);

					if (lead) {
						setLead(lead);
					}
				}

				setCasters(casters);
				setUsers(users);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados, tente novamente');
			}

			setFallback((prev) => ({ ...prev, initialData: false }));
		};

		fetchInitialData();
	}, [leadId, setLead]);

	const stepContents = useMemo(() => {
		return {
			0: (
				<PersonalInfos
					action='CREATE'
					isEmailTaken={isEmailTaken}
					setCurrentStep={setCurrentStep}
				/>
			),
			1: <AccountInfos action='CREATE' casters={casters} setCurrentStep={setCurrentStep} />,
			2: <SocialMedia action='CREATE' setCurrentStep={setCurrentStep} />,
			3: <Permissions action='CREATE' setCurrentStep={setCurrentStep} />,
			4: (
				<Modules
					action='CREATE'
					setCurrentStep={setCurrentStep}
					submitFn={handleSubmit}
					isLoading={fallback.creatingUser}
				/>
			),
			5: permissions.PROGRAMS_SHARING ? (
				<Network
					action='CREATE'
					users={users}
					setCurrentStep={setCurrentStep}
					submitFn={handleSubmit}
					isLoading={fallback.creatingUser}
				/>
			) : null,
		};
	}, [casters, permissions, isEmailTaken, fallback, users, handleSubmit]);

	const steps = useMemo(
		() => [
			{ title: 'Dados Pessoais', description: 'Dados pessoais do usuário' },
			{ title: 'Dados da Conta', description: 'Dados referentes à conta' },
			{ title: 'Mídias Sociais', description: 'Mídias sociais da rádio' },
			{ title: 'Permissões', description: 'Permissões do usuário' },
			{ title: 'Módulos', description: 'Funcionalidades disponíveis' },
			permissions.PROGRAMS_SHARING
				? { title: 'Rede', description: 'Rádios na rede do usuário' }
				: null,
		],
		[permissions]
	);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Novo usuário' />

			<PageHeader title='Novo Usuário' breadcrumb={breadcrumb}>
				<Typography.Text>Cadastrar novo usuário na TalkPlay</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					{lead && (
						<LeadInfo>
							<span>*</span>{' '}
							<small>
								Alguns campos foram preenchidos automaticamente com as informações fornecidas pelo
								lead
							</small>
						</LeadInfo>
					)}

					{lead && lead.planId?.type === PlanTypes.STARTER && (
						<Alert
							closable
							showIcon
							type='warning'
							style={{ marginBottom: 24 }}
							message='Conta Gratuíta'
							description='Este usuário terá acesso ao sistema pelos próximos 15 dias. Após esse período, o acesso será bloqueado automaticamente. Essa restrição pode ser removida a qualquer momento acessando a opção "Editar Usuário"'
						/>
					)}

					<StepsContainer>
						<Steps direction='vertical' current={currentStep}>
							{steps.map((step) =>
								step ? (
									<Steps.Step key={step.title} title={step.title} description={step.description} />
								) : null
							)}
						</Steps>
						<StepContentContainer>{stepContents[currentStep]}</StepContentContainer>
					</StepsContainer>
				</Card>
			</Container>
		</>
	);
};

export default NewUser;
