import React, { useMemo } from 'react';
import { Button, Divider, Radio, Slider, Switch } from 'antd';

import {
	PermissionItem,
	PermissionsContainer,
	PermissionItemDescription,
	ButtonsContainer,
} from '../styles';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useUpsertUser } from '../../../../../contexts/UserUpsert';
import { PlanTypes } from '../../../../../enums/plans';

const Permissions = ({ setCurrentStep, action }) => {
	const { permissions, handleChangePermission, lead } = useUpsertUser();

	const switchPermissions = useMemo(() => {
		return [
			{
				name: 'Forçar Assinatura',
				disabled: false,
				description:
					'Força a inserção de pelo menos uma assinatura em cada bloco dos programas gerados',
				checked: permissions.RADIO_SIGNATURE === 'MANDATORY',
				onChange: () =>
					handleChangePermission(
						'RADIO_SIGNATURE',
						permissions.RADIO_SIGNATURE === 'MANDATORY' ? 'OPTIONAL' : 'MANDATORY'
					),
			},
			{
				name: 'Assinatura Musical',
				disabled: false,
				description:
					'Permite a inserção de assinaturas nas músicas, caso o usuário possua assinaturas cadastradas',
				checked: permissions.TRACK_SIGNATURE,
				onChange: () => handleChangePermission('TRACK_SIGNATURE', !permissions.TRACK_SIGNATURE),
			},
			{
				name: 'Geração Noturna',
				disabled: false,
				description: 'Habilita a geração dos episódios durante a noite',
				checked: permissions.NOCTURNAL_GENERATION,
				onChange: () =>
					handleChangePermission('NOCTURNAL_GENERATION', !permissions.NOCTURNAL_GENERATION),
			},
			{
				name: 'Permitir Compartilhamento',
				disabled: lead?.planId?.type === PlanTypes.ENTERPRISE,
				description: 'Permite que o usuário compartilhe seus conteúdos com as rádios de sua rede',
				checked: permissions.PROGRAMS_SHARING,
				onChange: () => handleChangePermission('PROGRAMS_SHARING', !permissions.PROGRAMS_SHARING),
			},
		];
	}, [handleChangePermission, permissions, lead]);

	return (
		<>
			<PermissionsContainer>
				{switchPermissions.map((item) => (
					<PermissionItem onClick={item.onChange} key={item.name} disabled={item.disabled}>
						<PermissionItemDescription>
							<strong>{item.name}</strong>
							<span>{item.description}</span>
						</PermissionItemDescription>
						<Switch
							style={{ pointerEvents: 'none' }}
							checked={item.checked}
							disabled={item.disabled}
						/>
					</PermissionItem>
				))}

				<PermissionItem hideHover>
					<PermissionItemDescription>
						<strong>Masterização</strong>
						<span>Informa se a masterização será obrigatória, opcional ou indisponível</span>
					</PermissionItemDescription>

					<Radio.Group
						buttonStyle='solid'
						value={permissions.MASTERIZATION}
						onChange={(e) => handleChangePermission('MASTERIZATION', e.target.value)}>
						<Radio.Button value='MANDATORY'>Obrigatória</Radio.Button>
						<Radio.Button value='OPTIONAL'>Opcional</Radio.Button>
						<Radio.Button value='BLOCKED'>Bloqueada</Radio.Button>
					</Radio.Group>
				</PermissionItem>

				<Divider />

				<PermissionItem hideHover style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
					<PermissionItemDescription>
						<strong>Limite de Programas ({permissions.PROGRAMS_LIMIT})</strong>
						<span>Indica o máximo de programas que o usuário pode criar</span>
					</PermissionItemDescription>

					<Slider
						min={0}
						max={99}
						step={1}
						style={{ width: '98%' }}
						value={permissions.PROGRAMS_LIMIT}
						onChange={(value) => handleChangePermission('PROGRAMS_LIMIT', value)}
						marks={{
							0: 0,
							10: 10,
							20: 20,
							30: 30,
							40: 40,
							50: 50,
							60: 60,
							70: 70,
							80: 80,
							90: 90,
							99: 99,
						}}
					/>
				</PermissionItem>

				<PermissionItem hideHover style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
					<PermissionItemDescription>
						<strong>Limite de Blocos por Programa ({permissions.MAX_BLOCKS_PER_PROGRAM})</strong>
						<span>Indica o máximo de blocos que um programa pode conter</span>
					</PermissionItemDescription>
					<Slider
						min={1}
						max={25}
						step={1}
						style={{ width: '98%' }}
						value={permissions.MAX_BLOCKS_PER_PROGRAM}
						onChange={(value) => handleChangePermission('MAX_BLOCKS_PER_PROGRAM', value)}
						marks={{
							1: 1,
							5: 5,
							10: 10,
							15: 15,
							20: 20,
							25: 25,
						}}
					/>
				</PermissionItem>

				<PermissionItem hideHover style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
					<PermissionItemDescription>
						<strong>Limite de Músicas por Bloco ({permissions.MAX_MUSICS_PER_BLOCK})</strong>
						<span>Indica o máximo de músicas presentes em cada bloco</span>
					</PermissionItemDescription>
					<Slider
						min={0}
						max={25}
						step={1}
						style={{ width: '98%' }}
						value={permissions.MAX_MUSICS_PER_BLOCK}
						onChange={(value) => handleChangePermission('MAX_MUSICS_PER_BLOCK', value)}
						marks={{
							0: 0,
							5: 5,
							10: 10,
							15: 15,
							20: 20,
							25: 25,
						}}
					/>
				</PermissionItem>

				<PermissionItem hideHover style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
					<PermissionItemDescription>
						<strong>
							Máximo de Gerações por Programas ({permissions.MAX_GENERATIONS_PER_PROGRAM})
						</strong>
						<span>
							Indica o quantide máxia de vezes que um programa pode ser produzido em uma mesma data
						</span>
					</PermissionItemDescription>
					<Slider
						min={1}
						max={12}
						step={1}
						style={{ width: '98%' }}
						value={permissions.MAX_GENERATIONS_PER_PROGRAM}
						onChange={(value) => handleChangePermission('MAX_GENERATIONS_PER_PROGRAM', value)}
						marks={{
							1: 1,
							3: 3,
							6: 6,
							9: 9,
							12: 12,
						}}
					/>
				</PermissionItem>
			</PermissionsContainer>

			{action === 'CREATE' && (
				<ButtonsContainer>
					<Button type='default' size='large' onClick={() => setCurrentStep((prev) => prev - 1)}>
						<FiArrowLeft />
						Voltar
					</Button>
					<Button type='primary' size='large' onClick={() => setCurrentStep((prev) => prev + 1)}>
						<FiArrowRight />
						Continuar
					</Button>
				</ButtonsContainer>
			)}
		</>
	);
};

export { Permissions };
