import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import querystring from 'query-string';
import {
	Button,
	Card,
	Descriptions,
	Empty,
	message,
	PageHeader,
	Pagination,
	Tabs,
	Tag,
	Typography,
} from 'antd';

import { Container, LeadFooter, LeadHeader, LeadsList, LeadStatus } from './styles';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { LeadsStatus, LeadsStatusColors, LeadsStatusTranslations } from '../../../../enums/leads';
import { FiEdit3, FiFilter } from 'react-icons/fi';
import {
	ChangeLeadStatusModal,
	DEFAULT_FILTERS,
	LeadPlanDetailsModal,
	LeadsFiltersModal,
} from './modals';
import LeadsAPI from '../../../../services/sdks/leads';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { PlanTypesTranslations } from '../../../../enums/plans';
import useFormatter from '../../../../hooks/useFormatter';

const breadcrumb = {
	routes: [{ breadcrumbName: 'Painel do Administrador' }, { breadcrumbName: 'Leads' }],
	style: { marginBottom: 12 },
};

const DEFAULT_PAGINATION = { total: 0, current: 1, pageSize: 10 };

const ListLeads = () => {
	const format = useFormatter({ style: 'currency', currency: 'BRL' });
	const [leads, setLeads] = useState([]);
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [lead, setLead] = useState(null);
	const [fallback, setFallback] = useState({ initialData: true });
	const [filters, setFilters] = useState(DEFAULT_FILTERS);
	const [visibleModals, setVisibleModals] = useState({
		changeStatus: false,
		planDetails: false,
		filters: false,
	});

	const history = useHistory();
	const { search } = useLocation();
	const [tab, setTab] = useState(() => {
		const qs = querystring.parse(search);
		const status = qs.tab;
		const validStatuses = Object.values(LeadsStatus);
		return validStatuses.includes(status) ? status : LeadsStatus.NEW;
	});

	const visibleLeads = useMemo(() => {
		return leads.filter((l) => l.status === tab).length;
	}, [tab, leads]);

	const getStatusTab = useCallback((lead) => {
		return (
			<Tag style={{ width: 'max-content' }} color={LeadsStatusColors[lead.status]}>
				{LeadsStatusTranslations[lead.status]}
			</Tag>
		);
	}, []);

	const onCloseChangeStatusModal = useCallback((leadId, status) => {
		if (status) {
			setLeads((prev) =>
				prev.map((l) => (l._id === leadId ? { ...l, status, lastStatusChange: new Date() } : l))
			);
		}

		setLead(null);
		setVisibleModals((prev) => ({ ...prev, changeStatus: false }));
	}, []);

	const fetchLeads = useCallback(async (params) => {
		setFallback((prev) => ({ ...prev, fetchingLeads: true }));

		try {
			const { data } = await LeadsAPI.listLeads(params);
			setPagination((prev) => ({ ...prev, total: data.total }));
			setLeads(data.leads);
		} catch (error) {
			message.error('Erro ao buscar os leads, tente novamente');
		}

		setFallback((prev) => ({ ...prev, fetchingLeads: false, initialData: false }));
	}, []);

	const tabsList = useMemo(() => {
		return Object.values(LeadsStatus).map((s) => ({
			key: s,
			label: LeadsStatusTranslations[s],
		}));
	}, []);

	const onTabChange = useCallback((tab) => {
		setTab(tab);
		setPagination(DEFAULT_PAGINATION);
	}, []);

	useEffect(() => {
		fetchLeads({
			...filters,
			page: pagination.current - 1,
			limit: pagination.pageSize,
			status: tab,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchLeads, filters, tab, pagination.current, pagination.pageSize]);

	useEffect(() => {
		history.push(`${history.location.pathname}?tab=${tab}`);
	}, [history, tab]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Leads' />

			<PageHeader
				title='Leads'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='filter'
						onClick={() => setVisibleModals((prev) => ({ ...prev, filters: true }))}>
						<FiFilter /> Filtrar Leads
					</Button>,
				]}>
				<Typography.Text>Possíveis novos usuários</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Tabs onChange={onTabChange} activeKey={tab}>
						{tabsList.map((tab) => (
							<Tabs.TabPane key={tab.key} tab={tab.label}>
								{visibleLeads >= 1 ? (
									fallback.fetchingLeads ? (
										<Fallback title='Buscando leads' message='Por favor, aguarde...' />
									) : (
										<LeadsList>
											{leads
												.filter((l) => l.status === tab.key)
												.map((lead) => (
													<Card key={lead._id}>
														<LeadHeader>
															<LeadStatus>
																{getStatusTab(lead)}
																{lead.lastStatusChange && (
																	<small>
																		Status alterado{' '}
																		{formatDistance(new Date(lead.lastStatusChange), new Date(), {
																			locale: ptBR,
																			addSuffix: true,
																		})}
																	</small>
																)}
																{lead.status !== LeadsStatus.CONVERTED && (
																	<Button
																		size='small'
																		type='link'
																		onClick={() => {
																			setLead(lead);
																			setVisibleModals((prev) => ({ ...prev, changeStatus: true }));
																		}}>
																		<FiEdit3 />
																	</Button>
																)}
															</LeadStatus>
															<Typography.Text>
																Lead criado{' '}
																{formatDistance(new Date(lead.createdAt), new Date(), {
																	locale: ptBR,
																	addSuffix: true,
																})}
															</Typography.Text>
														</LeadHeader>
														<Descriptions>
															<Descriptions.Item label='Nome Completo'>
																{lead.name}
															</Descriptions.Item>
															<Descriptions.Item label='Nome da Empresa'>
																{lead.radioName}
															</Descriptions.Item>
															<Descriptions.Item label='Email'>{lead.email}</Descriptions.Item>
															<Descriptions.Item label='Telefone'>{lead.phone}</Descriptions.Item>
															<Descriptions.Item label='Plano Escolhido'>
																{PlanTypesTranslations[lead.planId.type]}
															</Descriptions.Item>
															<Descriptions.Item label='Valor do Plano'>
																{format(lead.planId.price)}
																<small>/mês</small>
															</Descriptions.Item>
														</Descriptions>
														<LeadFooter>
															<Button
																onClick={() => {
																	setLead(lead);
																	setVisibleModals((prev) => ({ ...prev, planDetails: true }));
																}}>
																Ver detalhes do plano
															</Button>
															{lead.status !== LeadsStatus.CONVERTED && (
																<Link to={`/admin/users/create-user?leadId=${lead._id}`}>
																	<Button type='primary'>Converter</Button>
																</Link>
															)}
														</LeadFooter>
													</Card>
												))}

											<Pagination
												onChange={(current) => setPagination({ ...pagination, current })}
												total={pagination.total}
												pageSize={pagination.pageSize}
												current={pagination.current}
											/>
										</LeadsList>
									)
								) : fallback.fetchingLeads ? (
									<Fallback title='Buscando leads' message='Por favor, aguarde...' />
								) : (
									<Empty
										style={{ marginTop: 35 }}
										description={`Não foram encontrados leads com o status "${tab.label}"`}
									/>
								)}
							</Tabs.TabPane>
						))}
					</Tabs>
				</Card>
			</Container>

			<ChangeLeadStatusModal
				lead={lead}
				isVisible={visibleModals.changeStatus}
				onCloseModal={onCloseChangeStatusModal}
			/>

			<LeadPlanDetailsModal
				lead={lead}
				isVisible={visibleModals.planDetails}
				onCloseModal={() => {
					setLead(null);
					setVisibleModals((prev) => ({ ...prev, planDetails: false }));
				}}
			/>

			<LeadsFiltersModal
				filters={filters}
				setFilters={setFilters}
				isVisible={visibleModals.filters}
				closeModal={() => setVisibleModals((prev) => ({ ...prev, filters: false }))}
			/>
		</>
	);
};

export default ListLeads;
