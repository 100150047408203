import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Button, Input } from 'antd';
import { FiArrowRight } from 'react-icons/fi';

import Form from '../../../../../components/Form';
import { ButtonsContainer } from '../styles';
import { useUpsertUser } from '../../../../../contexts/UserUpsert';

const SocialMedia = ({ setCurrentStep, action }) => {
	const { socialMediaInfos, personalInfos, handleChangeSocialMediaInfo } = useUpsertUser();
	const [errors, setErrors] = useState({});

	const linksSuffix = useMemo(() => {
		return personalInfos.radioName.toLowerCase().replaceAll(' ', '_');
	}, [personalInfos]);

	const nextStep = useCallback(async () => {
		try {
			const validationSchema = Yup.object().shape({
				website: Yup.string().url().nullable(true),
				instagram: Yup.string().url().nullable(true),
				youtube: Yup.string().url().nullable(true),
				facebook: Yup.string().url().nullable(true),
				tiktok: Yup.string().url().nullable(true),
			});

			await validationSchema.validate(socialMediaInfos, { abortEarly: false });
			setErrors({});
			setCurrentStep((step) => step + 1);
		} catch (error) {
			setErrors(
				Object.values(error.inner).reduce((err, { path }) => ({ ...err, [path]: true }), {})
			);
		}
	}, [setCurrentStep, socialMediaInfos]);

	return (
		<>
			<Form.Container layout='1fr 1fr 1fr 1fr'>
				<Form.Item optional size={2} label='Instagram' error={errors?.instagram}>
					<Input
						name='instagram'
						value={socialMediaInfos.instagram}
						size='large'
						placeholder={`Ex: https://instagram.com/${linksSuffix}`}
						onChange={handleChangeSocialMediaInfo}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='Canal do Youtube' error={errors?.youtube}>
					<Input
						name='youtube'
						value={socialMediaInfos.youtube}
						size='large'
						placeholder={`Ex: https://youtube.com/${linksSuffix}`}
						onChange={handleChangeSocialMediaInfo}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='Facebook' error={errors?.facebook}>
					<Input
						name='facebook'
						value={socialMediaInfos.facebook}
						size='large'
						placeholder={`Ex: https://facebook.com/${linksSuffix}`}
						onChange={handleChangeSocialMediaInfo}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='Tik Tok' error={errors?.tiktok}>
					<Input
						name='tiktok'
						value={socialMediaInfos.tiktok}
						size='large'
						placeholder={`Ex: https://tiktok.com/${linksSuffix}`}
						onChange={handleChangeSocialMediaInfo}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='Website' error={errors?.website}>
					<Input
						name='website'
						value={socialMediaInfos.website}
						size='large'
						placeholder={`Ex: https://www.${linksSuffix}.com.br`}
						onChange={handleChangeSocialMediaInfo}
					/>
				</Form.Item>
			</Form.Container>

			{action === 'CREATE' && (
				<ButtonsContainer>
					<Button type='primary' size='large' onClick={nextStep}>
						<FiArrowRight />
						Continuar
					</Button>
				</ButtonsContainer>
			)}
		</>
	);
};

export { SocialMedia };
