import React, { useCallback, useState } from 'react';

import CreateProgramContext from './context';
import ElementsAPI from '../../services/sdks/elements';
import { DEFAULT_INFOS, DEFAULT_VIGNETTES } from './defaultData';

const CreateProgramProvider = ({ children }) => {
	const [infos, setInfos] = useState(DEFAULT_INFOS);
	const [vignettes, setVignettes] = useState(DEFAULT_VIGNETTES);

	const handleChangeInfo = useCallback((e) => {
		e?.persist && e.persist();
		setInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const handleSelectVignettes = useCallback((type, selectedVignettes) => {
		setVignettes((prev) => ({
			...prev,
			[type]: [...selectedVignettes],
		}));
	}, []);

	const handleRemoveVignette = useCallback((index) => {
		setVignettes((prev) => {
			prev.splice(index, 1);
			return prev;
		});
	}, []);

	const getVignettesPromises = useCallback(
		({ userId, programId }) => {
			const getValidElements = (elements) => {
				return elements.filter((e) => e.status.code === 'VALID');
			};

			return Object.entries(vignettes)
				.filter(([, elements]) => getValidElements(elements).length !== 0)
				.map(([type, elements]) => {
					const payload = new FormData();

					payload.append('category', 'VIGNETTE');
					payload.append('type', type);
					payload.append('programId', programId);
					payload.append('userId', userId);
					getValidElements(elements).forEach((file, i) => payload.append(`file-${i}`, file.data));

					return ElementsAPI.store({ payload });
				});
		},
		[vignettes]
	);

	return (
		<CreateProgramContext.Provider
			value={{
				infos,
				vignettes,
				handleChangeInfo,
				handleRemoveVignette,
				handleSelectVignettes,
				getVignettesPromises,
			}}>
			{children}
		</CreateProgramContext.Provider>
	);
};

export default CreateProgramProvider;
