import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px 16px 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const LeadContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const LeadHeader = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;

export const LeadFooter = styled.footer`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 4px;
	gap: 8px;
`;

export const LeadStatus = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
	justify-content: center;
`;

export const LeadsList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`;

export const PlanDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const PlanDetailsInfos = styled.div`
	display: flex;
	gap: 4px;
	flex-direction: column;
	padding: 12px;
	border: 1px solid #dedede;
	border-radius: 8px;

	& > div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > span {
			font-size: 13px;
		}

		& > strong {
			color: #000;
			font-weight: 500;
		}
	}
`;


export const PlanServices = styled.ul`
	padding-left: 32px;
	margin-top: 2px;
`;