import React, { useCallback, useMemo } from 'react';
import { Button, Typography } from 'antd';

import {
	NetworkContainer,
	NetworkSection,
	NetworkUsersList,
	NetworkItem,
	ButtonsContainer,
	NetworkHeader,
} from '../styles';
import { alphabet } from '../../../../../constants';
import { FiArrowLeft, FiSave } from 'react-icons/fi';
import { useUpsertUser } from '../../../../../contexts/UserUpsert';
import { PlanTypes } from '../../../../../enums/plans';

const Network = ({ users, setCurrentStep, submitFn, action, isLoading }) => {
	const { network, setNetwork, lead } = useUpsertUser();
	const radiosLimit = useMemo(() => {
		if (lead && lead.planId?.type === PlanTypes.ENTERPRISE) {
			return lead.planId.radioQuantity;
		}

		return users.length;
	}, [lead, users]);

	const isUserSelected = useCallback(
		(userId) => {
			return network.includes(userId);
		},
		[network]
	);

	const onUserClick = useCallback(
		(userId) => {
			setNetwork((prev) =>
				isUserSelected(userId) ? [...prev.filter((id) => id !== userId)] : [...prev, userId]
			);
		},
		[isUserSelected, setNetwork]
	);

	const handleSelectAll = useCallback(() => {
		setNetwork(users.length === network.length ? [] : users.map((u) => u._id));
	}, [network, setNetwork, users]);

	const renderSection = useCallback(
		(letter) => {
			const data = users
				.map((u) => ({
					...u,
					normalizaedRadioName: u.radioName.toUpperCase().replace('RADIO ', ''),
				}))
				.filter((u) => u.normalizaedRadioName.charAt(0) === letter);

			if (!data.length) {
				return null;
			}

			return (
				<NetworkSection key={letter}>
					<header>
						<Typography.Title level={2}>{letter}</Typography.Title>
					</header>
					<NetworkUsersList>
						{data.map((u) => (
							<NetworkItem
								key={u._id}
								checked={isUserSelected(u._id)}
								disabled={network.length === radiosLimit}
								onClick={() => onUserClick(u._id)}>
								<div>
									<strong>{u.radioName.toUpperCase()}</strong>
									<span>{u.email}</span>
								</div>
							</NetworkItem>
						))}
					</NetworkUsersList>
				</NetworkSection>
			);
		},
		[users, isUserSelected, network, radiosLimit, onUserClick]
	);

	const handleSubmit = useCallback(async () => {
		await submitFn();
	}, [submitFn]);

	return (
		<>
			<NetworkHeader>
				<small>
					{network.length} de {radiosLimit} rádios selecionadas
				</small>
				{radiosLimit === users.length && (
					<Button onClick={handleSelectAll} type='default'>
						{network.length === users.length ? 'Remover' : 'Selecionar'} Todos
					</Button>
				)}
			</NetworkHeader>
			<NetworkContainer>{alphabet.map(renderSection)}</NetworkContainer>
			{action === 'CREATE' && (
				<ButtonsContainer>
					<Button type='default' size='large' onClick={() => setCurrentStep((prev) => prev - 1)}>
						<FiArrowLeft />
						Voltar
					</Button>
					<Button type='primary' size='large' onClick={handleSubmit} loading={isLoading}>
						<FiSave />
						Finalizar Cadastro
					</Button>
				</ButtonsContainer>
			)}
		</>
	);
};

export { Network };
