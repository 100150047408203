import React, { useState, useMemo, useCallback } from 'react';

import { Button, Tabs } from 'antd';

import { useCreateProgram } from '../../../../../contexts/CreateProgram';
import FilesUploader from '../../../../../components/FilesUploader';
import { ButtonsContainer } from '../styles';
import { FiArrowLeft, FiSave } from 'react-icons/fi';

export const ProgramVignettesStep = ({ createProgram, prevStep }) => {
	const [isCreating, setIsCreating] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const { handleSelectVignettes, vignettes } = useCreateProgram();

	const TABS = useMemo(() => {
		const getAmount = (type) => {
			const amount = vignettes[type].filter((f) => f.status.code === 'VALID').length;
			return amount ? `(${amount})` : '';
		};

		return {
			'VIGNETTE-PROGRAM-INTRO': `Abertura de Programa ${getAmount('VIGNETTE-PROGRAM-INTRO')}`,
			'VIGNETTE-PROGRAM-OUTRO': `Fechamento de Programa ${getAmount('VIGNETTE-PROGRAM-OUTRO')}`,
			'VIGNETTE-BLOCK-INTRO': `Abertura de Bloco ${getAmount('VIGNETTE-BLOCK-INTRO')}`,
			'VIGNETTE-BLOCK-OUTRO': `Fechamento de Bloco ${getAmount('VIGNETTE-BLOCK-OUTRO')}`,
			'VIGNETTE-BREAK': `Passagem ${getAmount('VIGNETTE-BREAK')}`,
		};
	}, [vignettes]);

	const handleCreateProgram = useCallback(async () => {
		setIsCreating(true);
		await createProgram();
	}, [createProgram]);

	return (
		<>
			<Tabs>
				{Object.entries(TABS).map(([key, label]) => (
					<Tabs.TabPane key={key} tab={label} disabled={isValidating}>
						<FilesUploader
							isDisabled={isValidating}
							multiple={true}
							onChange={(files) => handleSelectVignettes(key, files)}
							validTypes={['.mp3', '.wav']}
							onStartValidation={() => setIsValidating(true)}
							onEndValidation={() => setIsValidating(false)}
						/>
					</Tabs.TabPane>
				))}
			</Tabs>

			<ButtonsContainer>
				<Button type='default' size='large' onClick={prevStep} disabled={isValidating}>
					<FiArrowLeft />
					Voltar
				</Button>
				<Button
					type='primary'
					size='large'
					onClick={handleCreateProgram}
					loading={isCreating}
					disabled={isValidating}>
					<FiSave />
					Cadastrar Programa
				</Button>
			</ButtonsContainer>
		</>
	);
};
