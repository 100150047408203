export const DEFAULT_INFOS = {
	name: 'TESTE',
	description: 'TESTE',
	audience: 'TESTE',
	theme: 'TESTE',
	filenameTemplate: 'TESTE_0',
	genreId: undefined,
};
// export const DEFAULT_INFOS = {
// 	name: '',
// 	description: '',
// 	audience: '',
// 	theme: '',
// 	filenameTemplate: '',
// 	genreId: undefined,
// };

export const DEFAULT_VIGNETTES = {
	'VIGNETTE-PROGRAM-INTRO': [],
	'VIGNETTE-PROGRAM-OUTRO': [],
	'VIGNETTE-BLOCK-INTRO': [],
	'VIGNETTE-BLOCK-OUTRO': [],
	'VIGNETTE-BREAK': [],
};
