import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import Form from '../../../../../components/Form';
import { Button, Input, Select } from 'antd';
import { useCreateProgram } from '../../../../../contexts/CreateProgram';
import { ButtonsContainer } from '../styles';
import { FiArrowRight } from 'react-icons/fi';

export const ProgramInfosStep = ({ genres, nextStep }) => {
	const [errors, setErrors] = useState({
		name: null,
		description: null,
		audience: null,
		theme: null,
		filenameTemplate: null,
		genreId: null,
	});

	const { handleChangeInfo, infos } = useCreateProgram();

	const onNextStep = useCallback(async () => {
		const validationSchema = Yup.object().shape({
			name: Yup.string().required(),
			description: Yup.string().required(),
			audience: Yup.string().required(),
			theme: Yup.string().required(),
			filenameTemplate: Yup.string().required(),
			genreId: Yup.string().required(),
		});

		try {
			setErrors({});
			await validationSchema.validate(infos, { abortEarly: false });
			nextStep();
		} catch (error) {
			console.log(error);
			setErrors(
				Object.values(error.inner).reduce((err, { path }) => ({ ...err, [path]: true }), {})
			);
		}
	}, [infos, nextStep]);

	return (
		<>
			<Form.Container layout='1fr 1fr 1fr 1fr 1fr 1fr'>
				<Form.Item size={2} label='Nome' error={errors?.name}>
					<Input
						name='name'
						value={infos.name}
						size='large'
						placeholder='Nome do programa'
						onChange={handleChangeInfo}
					/>
				</Form.Item>
				<Form.Item size={2} label='Público Alvo' error={errors?.audience}>
					<Input
						name='audience'
						value={infos.audience}
						size='large'
						placeholder='Ex: Jovens adultos entre 18 e 35 anos'
						onChange={handleChangeInfo}
					/>
				</Form.Item>
				<Form.Item size={2} label='Tema do Programa' error={errors?.theme}>
					<Input
						name='theme'
						value={infos.theme}
						size='large'
						placeholder='Ex: Entretenimento e música pop'
						onChange={handleChangeInfo}
					/>
				</Form.Item>
				<Form.Item
					size={3}
					label='Padrão do Nome dos Arquivos'
					help='Nome padrão atribuído aos arquivos dos blocos baixados após a geração dos episódios'
					error={errors?.filenameTemplate}>
					<Input
						name='filenameTemplate'
						value={infos.filenameTemplate}
						size='large'
						placeholder='Padrão do nome dos arquivos'
						onChange={handleChangeInfo}
					/>
				</Form.Item>
				<Form.Item size={3} label='Gênero' error={errors?.genreId}>
					<Select
						showSearch
						size='large'
						value={infos.genreId}
						onChange={(value) => handleChangeInfo({ target: { name: 'genreId', value } })}
						placeholder='Selecione o gênero'
						filterOption={(input, { props: { children } }) => {
							return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
						}}>
						{genres.map((genre) => (
							<Select.Option key={genre._id} value={genre._id}>
								{genre.name.toUpperCase()}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					style={{ marginTop: 12 }}
					size={6}
					label='Descrição do programa'
					error={errors?.description}>
					<Input
						name='description'
						value={infos.description}
						size='large'
						placeholder='Descreva de forma clara sobre o que se trata o programa, tipo de música, horário que vai ao ar, forma de locução, etc...'
						onChange={handleChangeInfo}
					/>
				</Form.Item>
			</Form.Container>

			<ButtonsContainer>
				<Button type='primary' size='large' onClick={onNextStep}>
					<FiArrowRight />
					Continuar
				</Button>
			</ButtonsContainer>
		</>
	);
};
