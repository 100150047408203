import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import arrayMove from 'array-move';

import PlayerContext from './context';
import { getResource } from '../../helpers/getResource';
import resourcesKeys from '../../constants/resourcesKeys';
import userTypes from '../../constants/userTypes';

const DEFAULT_ELEMENTS = {
	cashTracks: [],
	templateVignettes: [],
	customs: [],
	merchans: [],
	trackAds: [],
};

const ScriptProvider = ({ children, user }) => {
	const [elements, setElements] = useState(DEFAULT_ELEMENTS);
	const [deletedSoundtracks, setDeletedSoundtracks] = useState([]);
	const [program, setProgram] = useState(null);
	const [isSorting, setIsSorting] = useState(false);
	const [compact, setCompact] = useState(false);
	const [script, setScript] = useState({
		name: '',
		body: [],
		program: null,
		userId: null,
		isShared: null,
		originalScript: null,
		wasModified: null,
	});

	const limits = useMemo(() => {
		if (![userTypes.ADMIN, userTypes.TALK].includes(user?.type)) {
			return {
				CASHTRACK: 2,
				OFFERINGS: 3,
				SPONSORS: 3,
				TESTIMONIALS: 3,
			};
		}

		return {
			CASHTRACK: 2,
			OFFERINGS: 3,
			SPONSORS: 3,
			TESTIMONIALS: 3,
			BLOCKS: getResource(user, resourcesKeys.MAX_BLOCKS_PER_PROGRAM),
			TRACKS: getResource(user, resourcesKeys.MAX_MUSICS_PER_BLOCK),
		};
	}, [user]);

	const validateLimits = useCallback(
		({ body, blockIndex, type }) => {
			if (!limits) {
				return { error: false };
			}

			const blocks = body.reduce((_blocks, element) => {
				if (element.type === 'NEW-BLOCK') {
					return (_blocks = [..._blocks, 0]);
				}

				if (element.type === 'TRACK') {
					_blocks[_blocks.length - 1] = _blocks[_blocks.length - 1] + 1;
				}

				return _blocks;
			}, []);

			if (type === 'NEW-BLOCK') {
				if (blocks?.length > limits?.BLOCKS) {
					return { error: true, message: 'Limite de blocos foi atingido' };
				}
			}

			if (type === 'TRACK') {
				let tracksError = { error: false };

				blocks.every((tracksCount, i) => {
					if (blockIndex) {
						/** valida somente o bloco passado */
						if (tracksCount > limits?.TRACKS && i === blockIndex) {
							tracksError = {
								error: true,
								message: `Limite de músicas no bloco ${i + 1} foi atingido`,
							};

							return false;
						}
					} else {
						/** valida todos os blocos */
						if (tracksCount > limits?.TRACKS) {
							tracksError = {
								error: true,
								message: `Limite de músicas no bloco ${i + 1} foi atingido`,
							};

							return false;
						}
					}

					return true;
				});

				return tracksError;
			}

			return { error: false };
		},
		[limits]
	);

	const handleAddElement = useCallback(
		({ index, blockIndex, element, insertions = 1 }) => {
			let updatedBody = [...script.body];
			const insertedElements = Array.from({ length: insertions }).fill(element);

			index
				? updatedBody.splice(index, 0, ...insertedElements)
				: updatedBody.push(...insertedElements);

			blockIndex = blockIndex
				? blockIndex
				: script.body.filter((e) => e.type === 'NEW-BLOCK').length - 1;

			const { error, message: errorMessage } = validateLimits({
				blockIndex,
				body: updatedBody,
				type: element.type,
			});

			if (error) {
				updatedBody = script.body;
				return message.error(errorMessage);
			}

			setScript({ ...script, body: updatedBody });
		},
		[script, validateLimits]
	);

	const handleAddTrackAdd = useCallback(
		({ index, element, placement }) => {
			const updatedBody = [...script.body];
			placement === 'before'
				? updatedBody.splice(index, 0, element)
				: updatedBody.splice(index + 1, 0, element);

			setScript({ ...script, body: updatedBody });
		},
		[script]
	);

	const handleAddTrackPresentation = useCallback(
		({ index, type }) => {
			const updatedBody = [...script.body];
			type === 'TRACK-CALL'
				? updatedBody.splice(index, 0, { type, options: null })
				: updatedBody.splice(index + 1, 0, { type, options: null });

			setScript({ ...script, body: updatedBody });
		},
		[script]
	);

	const handleAddMerchanElement = useCallback(
		({ blockIndex, element, insertions = 1 }) => {
			let inserts = [];
			let blocks = script.body.reduce((_blocks, element) => {
				if (element.type === 'NEW-BLOCK') {
					return (_blocks = [..._blocks, []]);
				}

				_blocks[_blocks.length - 1] = [..._blocks[_blocks.length - 1], element];

				return _blocks;
			}, []);

			const modifiedBlock = blocks[typeof blockIndex === 'number' ? blockIndex : blocks.length - 1];

			if (program?.isTalkProgram) {
				const { type } = element;

				switch (type) {
					case 'TESTIMONIAL':
						const testimonialsInBlock = modifiedBlock.filter(({ type }) => {
							return type === 'TESTIMONIAL';
						}).length;

						if (testimonialsInBlock >= limits?.TESTIMONIALS) {
							return message.warn('Esse bloco atingiu o limite de testemunhais');
						}

						inserts = Array.from({ length: insertions }).fill(element);
						modifiedBlock.splice(0, 0, ...inserts);

						break;
					case 'OFFERING':
						const offeringsInBlock = modifiedBlock.filter(({ type }) => type === 'OFFERING').length;

						if (offeringsInBlock >= limits?.OFFERINGS) {
							return message.warn('Esse bloco atingiu o limite de oferecimentos');
						}

						const introPosition = modifiedBlock.findIndex(({ type }) => {
							return ['VIGNETTE-BLOCK-INTRO', 'VIGNETTE-PROGRAM-INTRO'].includes(type);
						});

						if (introPosition === -1) {
							return message.warn(
								'Para inserir um oferecimento é necessário ter no mínimo uma vinheta de abertura no bloco'
							);
						}

						inserts = Array.from({ length: insertions }).fill(element);
						modifiedBlock.splice(introPosition + 1, 0, ...inserts);

						break;
					case 'SPONSOR':
						const sponsorsInBlock = modifiedBlock.filter(({ type }) => type === 'SPONSOR').length;

						if (sponsorsInBlock >= limits?.SPONSORS) {
							return message.warn('Esse bloco atingiu o limite de patrocínios');
						}

						const outroPosition = modifiedBlock.findIndex(({ type }) => {
							return ['VIGNETTE-BLOCK-OUTRO', 'VIGNETTE-PROGRAM-OUTRO'].includes(type);
						});

						if (outroPosition === -1) {
							return message.warn(
								'Para inserir um patrocínio é necessário ter no mínimo uma vinheta de fechamento no bloco'
							);
						}

						inserts = Array.from({ length: insertions }).fill(element);
						modifiedBlock.splice(outroPosition, 0, ...inserts);

						break;
					case 'CASHTRACK':
						const cashTracksInBlock = modifiedBlock.filter(({ type }) => {
							return type === 'CASHTRACK';
						}).length;

						if (cashTracksInBlock >= limits?.CASHTRACK) {
							return message.warn('Esse bloco atingiu o limite de músicas cash');
						}

						inserts = Array.from({ length: insertions }).fill(element);
						modifiedBlock.splice(0, 0, ...inserts);

						break;
					default:
						return message.error('INVALID ELEMENT TYPE');
				}
			} else {
				inserts = Array.from({ length: insertions }).fill(element);
				modifiedBlock.splice(0, 0, ...inserts);
			}

			message.success(
				`Elemento adicionado ao bloco ${blockIndex ? blockIndex + 1 : blocks.length}`
			);

			return setScript({
				...script,
				body: blocks
					.map((block, index) => {
						if (index === blockIndex) {
							return modifiedBlock;
						}

						return block;
					})
					.reduce((_body, block) => {
						_body = [..._body, { type: 'NEW-BLOCK', options: null }, ...block];

						return _body;
					}, []),
			});
		},
		[script, program, limits]
	);

	const handleRemoveElement = useCallback(
		({ index }) => {
			const element = script.body[index];
			const nextElement = script.body[index + 1];
			const lastElement = script.body[index - 1];

			const removedIndexes = [index];

			if (element.type === 'TRACK') {
				if (['TRACK-POST', 'TRACK-AD'].includes(nextElement?.type)) removedIndexes.push(index + 1);
				if (['TRACK-CALL', 'TRACK-AD'].includes(lastElement?.type)) removedIndexes.push(index - 1);
			}

			if (removedIndexes.length !== 1) {
				message.warn(
					'Propagandas curtas e chamadas/saídas adjacentes foram removidas juntamente da música'
				);
			}
			
			setScript(({ body, ...prev }) => ({
				...prev,
				body: body.filter((_, i) => !removedIndexes.includes(i)),
			}));

			if (typeof element?.options?.specificSoundtrack === 'string') {
				setDeletedSoundtracks((prev) => [...prev, element?.options?.specificSoundtrack]);
			}
		},
		[script]
	);

	const handleRemoveBlock = useCallback(
		({ blockIndex }) => {
			const groupedBlocks = script.body
				.reduce((_blocks, element) => {
					if (element.type === 'NEW-BLOCK') {
						return (_blocks = [..._blocks, []]);
					}

					_blocks[_blocks.length - 1] = [..._blocks[_blocks.length - 1], element];

					return _blocks;
				}, [])
				.filter((_, i) => i !== blockIndex);

			setScript({
				...script,
				body: groupedBlocks.reduce((_body, block) => {
					_body = [..._body, { type: 'NEW-BLOCK', options: null }, ...block];

					return _body;
				}, []),
			});

			return message.success(`O bloco ${blockIndex + 1} foi removido`);
		},
		[script]
	);

	const handleCloneBlock = useCallback(
		({ blockIndex }) => {
			const groupedBlocks = script.body.reduce((_blocks, element) => {
				if (element.type === 'NEW-BLOCK') {
					return (_blocks = [..._blocks, []]);
				}

				_blocks[_blocks.length - 1] = [..._blocks[_blocks.length - 1], element];

				return _blocks;
			}, []);

			if (groupedBlocks.length >= limits?.BLOCKS) {
				return message.warn('Limite de blocos foi atingido');
			}

			const blockElement = {
				index: 0,
				indexLabel: null,
				type: 'NEW-BLOCK',
				options: null,
			};

			const scriptBody = [];

			groupedBlocks.splice(blockIndex + 1, 0, groupedBlocks[blockIndex]);
			groupedBlocks.forEach((elements) => {
				scriptBody.push({ ...blockElement }, ...elements);
			});

			setScript({
				...script,
				body: scriptBody.map((e, index) => ({ ...e, index })),
			});

			return message.success(
				`O bloco ${blockIndex + 2} foi criado, idêntico ao bloco ${blockIndex + 1}`
			);
		},
		[script, limits]
	);

	const onElementMove = useCallback(
		({ oldIndex, newIndex }) => {
			const element = script.body[oldIndex];
			const movedElements = [element];
			let updatedBody = [...script.body];

			if (program?.isTalkProgram) {
				if (['VIGNETTE-PROGRAM-INTRO', 'VIGNETTE-BLOCK-INTRO'].includes(element?.type)) {
					/**
					 * Elemento movido é uma vinheta de aberta
					 * Move junto possíveis oferecimentos que venham após a vinheta
					 */
					let offeringsCount = 0;

					for (let index = 0; index < 3; index++) {
						const _e = script.body[oldIndex + index + 1];

						if (_e?.type === 'OFFERING') {
							movedElements.push(_e);
							offeringsCount = offeringsCount + 1;
						}
					}

					updatedBody.splice(oldIndex, movedElements.length);

					if (oldIndex < newIndex) {
						updatedBody.splice(newIndex - offeringsCount, 0, ...movedElements);
					} else {
						updatedBody.splice(newIndex, 0, ...movedElements);
					}
				} else if (['VIGNETTE-PROGRAM-OUTRO', 'VIGNETTE-BLOCK-OUTRO'].includes(element?.type)) {
					/**
					 * Elemento movido é uma vinheta de encerramento
					 * Move junto possíveis patrocínios que venham após a vinheta
					 */
					let sponsorsCount = 0;

					for (let index = 0; index < 3; index++) {
						const _e = script.body[oldIndex - (index + 1)];

						if (_e?.type === 'SPONSOR') {
							movedElements.unshift(_e);
							sponsorsCount = sponsorsCount + 1;
						}
					}

					updatedBody.splice(oldIndex - sponsorsCount, movedElements.length);

					if (oldIndex > newIndex) {
						updatedBody.splice(newIndex, 0, ...movedElements);
					} else {
						updatedBody.splice(newIndex - sponsorsCount, 0, ...movedElements);
					}
				} else {
					/** Não é vinheta de abertura/encerramento */
					updatedBody = arrayMove(script.body, oldIndex, newIndex);
				}
			} else {
				/** Não é programa Talk */
				updatedBody = arrayMove(script.body, oldIndex, newIndex);
			}

			setIsSorting(false);

			const { error, message: errorMessage } = validateLimits({
				body: updatedBody,
				type: element.type,
			});

			if (error) {
				return message.error(errorMessage);
			}

			setScript({ ...script, body: updatedBody });
		},
		[script, program, validateLimits]
	);

	const handleToggleElementSoundtrack = useCallback(({ index, withSoundtrack }) => {
		setScript(({ body, ...rest }) => ({
			...rest,
			body: body.map((element, i) => {
				if (i === index) {
					return { ...element, options: { ...element.options, withSoundtrack } };
				}

				return element;
			}),
		}));
	}, []);

	const handleSetElementSpecificSoundtrack = useCallback(({ index, soundtrackId }) => {
		setScript(({ body, ...rest }) => ({
			...rest,
			body: body.map((element, i) => {
				if (i === index) {
					return { ...element, options: { ...element.options, specificSoundtrack: soundtrackId } };
				}

				return element;
			}),
		}));
	}, []);

	const handleChangeElementOptions = useCallback(({ index, options }) => {
		setScript(({ body, ...rest }) => ({
			...rest,
			body: body.map((element, i) => {
				if (i === index) {
					return { ...element, options: { ...element.options, ...options } };
				}

				return element;
			}),
		}));
	}, []);

	const handleChangeElementExecutionMode = useCallback(({ index, executionMode }) => {
		setScript(({ body, ...rest }) => ({
			...rest,
			body: body.map((element, i) => {
				if (i === index) {
					return { ...element, options: { ...element.options, executionMode } };
				}

				return element;
			}),
		}));
	}, []);

	const handleChangeElementSpeechModeMode = useCallback(({ index, speechMode }) => {
		setScript(({ body, ...rest }) => ({
			...rest,
			body: body.map((element, i) => {
				if (i === index) {
					return { ...element, options: { ...element.options, speechMode } };
				}

				return element;
			}),
		}));
	}, []);

	const handleRemoveElementSpecificSoundtrack = useCallback(({ index }) => {
		setScript(({ body, ...rest }) => ({
			...rest,
			body: body.map((element, i) => {
				if (i === index) {
					return { ...element, options: { ...element.options, specificSoundtrack: null } };
				}

				return element;
			}),
		}));
	}, []);

	const cleanUp = useCallback(() => {
		setElements(DEFAULT_ELEMENTS);
		setProgram(null);
		setIsSorting(false);
		setScript({
			name: '',
			body: [],
			program: null,
			userId: null,
			isShared: null,
			originalScript: null,
			wasModified: null,
		});
	}, []);

	const loadBlockTemplate = useCallback(
		(index, template) => {
			const body = [...script.body];
			body.splice(index + 1, 0, ...template.body);
			setScript((prev) => ({ ...prev, body }));
		},
		[script]
	);

	return (
		<PlayerContext.Provider
			value={{
				cleanUp,
				elements,
				setElements,
				script,
				body: script?.body,
				deletedSoundtracks,
				setScript,
				program,
				setProgram,
				loadBlockTemplate,
				validateLimits,
				onElementMove,
				changeElementOptions: handleChangeElementOptions,
				changeElementExecutionMode: handleChangeElementExecutionMode,
				changeElementSpeechModeMode: handleChangeElementSpeechModeMode,
				addElement: handleAddElement,
				removeElement: handleRemoveElement,
				addTrackAdd: handleAddTrackAdd,
				cloneBlock: handleCloneBlock,
				removeBlock: handleRemoveBlock,
				addMerchanElement: handleAddMerchanElement,
				toggleElementSoundtrack: handleToggleElementSoundtrack,
				setElementSpecificSoundtrack: handleSetElementSpecificSoundtrack,
				removeElementSpecificSoundtrack: handleRemoveElementSpecificSoundtrack,
				addTrackPresentation: handleAddTrackPresentation,
				isSorting,
				setIsSorting,
				compact,
				setCompact,
				limits,
			}}>
			{children}
		</PlayerContext.Provider>
	);
};

export default connect(({ user }) => ({ user }))(ScriptProvider);
